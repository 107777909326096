* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  --primary-color: #262579;
  --primary-color-2: #003366;
  --secondary-color: #25BAD4;
  --secondary-color-2: #B3EAF3;
  --detail-color: #87288C;
  --success-color: #16DB93;
  --warning-color: #FFE66D;
  --error-color: #FF6B6B;
  --white-color: #e3e3e3;
  /* --primary-color-2x: #3B3F99; */
  /* --itc-color: #003366; */
  /* --itc-color-2: #133664; */
}

/* https://material.angular.io/guide/theming#using-a-pre-built-theme */
/* https://material.angular.io/guide/theming#using-a-pre-built-theme
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
}

app-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

main {
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
  padding: 0 6% 6% 6%;
}

.mat-mdc-button .mat-mdc-button-touch-target,
.mat-mdc-unelevated-button .mat-mdc-button-touch-target {
  height: 100% !important;
}

.loading-container {
  display: flex;
  justify-content: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: white;
  z-index: 12345678;
}

.loading-container .mat-mdc-progress-spinner {
  height: 100px !important;
  width: 100px !important;
}

.mat-mdc-progress-spinner {
  display: flex !important;
}

.main-content-header-section,
.secondary-content-header-section {
  text-align: left;
  width: 100%;
}

.main-content-header-section h1 {
  color: var(--primary-color-2);
  font-size: 48px;
  margin: 0;
}

.secondary-content-header-section h1 {
  color: var(--primary-color-2);
  font-size: 32px;
  margin: 0;
}

.promotional-value {
  color: var(--primary-color-2);
  font-size: 12px;
}

.main-content-header-section h5 {
  color: var(--primary-color-2);
  margin: 8px 0;
  font-size: 12px;
}

.main-content-header-section .header-description,
.secondary-content-header-section .header-description {
  color: black;
  font-size: 12px;
  margin: 0 0 12px 0;
}

.return {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.mat-step-content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  min-height: 250px;
}

mat-form-field {
  margin-bottom: 8px;
}

.mdc-tab-indicator {
  border: 1px solid var(--white-color);
}

.dual-field-row {
  display: flex;
  justify-content: space-between;
}

.dual-field {
  width: 48%;
}

/* Snackbar */
.green-snackbar button {
  background-color: var(--success-color) !important;
  color: white !important;
}

.red-snackbar button {
  background-color: var(--error-color) !important;
  color: white !important;
}

/* Dialog */
.success-dialog .dialog-title {
  background-color: var(--success-color) !important;
  color: white !important;
}

.warning-dialog .dialog-title {
  background-color: var(--warning-color) !important;
  color: black !important;
}

.failure-dialog .dialog-title {
  background-color: var(--error-color) !important;
  color: white !important;
}

.responsive-dialog {
  min-width: 40vw !important;
  max-width: 90vw !important;
  min-height: 25vh !important;
  max-height: 70vh !important;
}

.dialog-actions .primary-button {
  background: var(--primary-color-2);
  margin: 2px;
  color: white !important;
}

.dialog-actions .secondary-button {
  background: white;
  padding: 2px;
  margin: 2px;
  color: var(--primary-color-2) !important;
}

.person-type-toggle {
  font-size: 12px;
}

/* Media Queries */
@media screen and (max-width: 992px) {}

@media screen and (max-width: 860px) {
  .main-content-header-section h1 {
    text-align: center;
    margin: 0.2em 0 0 0;
  }
}

@media screen and (max-width: 600px) {
  .main-content-header-section h1 {
    font-size: 40px;
  }

  .secondary-content-header-section h1 {
    font-size: 28px;
  }

  .dual-field-row {
    flex-direction: column;
  }

  .dual-field {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .main-content-header-section h1 {
    font-size: 24px;
  }

  .secondary-content-header-section h1 {
    font-size: 16px;
  }

  .form-field-extra-error-margin {
    margin-bottom: 28px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* BE.COMPLIANCE */
/* floater */
.adopt-c-cyPWlR {
  background-color: var(--secondary-color-2) !important;
  opacity: 1 !important;
}

/* accept */
.adopt-c-blcsFr {
  bottom: 90px !important;
  left: 20px !important;
  width: 90% !important;
  max-width: 350px !important;
}

/* options */
.adopt-c-bdojk {
  bottom: 90px !important;
  left: 20px !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}